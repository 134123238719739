import React, { Component } from "react";
import cons from "../cons.js";

import abi_TOKENTRC20 from "../abi/tokenTRC20";

import { TronLinkAdapter } from '@tronweb3/tronwallet-adapter-tronlink';
import TronWeb from 'tronweb';

const BigNumber = require('bignumber.js');
function delay(s) { return new Promise(res => setTimeout(res, s * 1000)); }

let KEYS = (process.env.REACT_APP_APIKY).split(",")


const tronWeb = new TronWeb({
  fullHost: "https://api.trongrid.io",
  headers: { "TRON-PRO-API-KEY": KEYS[0] }
})

let adapter = new TronLinkAdapter();

/*
// create a send TRX transaction
const unSignedTransaction = await tronWeb.transactionBuilder.sendTrx(targetAddress, 100, adapter.address);
// using adapter to sign the transaction
const signedTransaction = await adapter.signTransaction(unSignedTransaction);
// broadcast the transaction
await tronWeb.trx.sendRawTransaction(signedTransaction);
*/

// create a send TRX transaction
let arrancado = false;
let cargandoTokens = false;

export default class CiroTrx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      estatus: {
        loggedIn: false,
        installed: false,
        web3: null
      },
      contrato: {
        web3: null,
        ciro_trx: null
      },
      wallet: "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb",
      elementSelect: <option value={0}>Loading...</option>,
      tokenSelected: "Loading...",
      tokenContratos: [],
      disponible: 0,
      recivedAmount: 0,
      idMoneda: 0,
      modalTitle: "Title",
      modalBody: "body",
      contractready: false,
      energyContrato: 0

    };

    this.conectar = this.conectar.bind(this);

    this.compra = this.compra.bind(this);
    this.estado = this.estado.bind(this);
    this.opciones = this.opciones.bind(this);

    this.rentEnergy = this.rentEnergy.bind(this);
    this.recursos = this.recursos.bind(this);
    this.calcularRecurso = this.calcularRecurso.bind(this);
    this.test = this.test.bind(this);

  }

  async componentDidMount() {
    document.title = "Dapp | CiroTrx"


    window.addEventListener('message', async (e) => {

      if (e.data.message && (e.data.message.action === "accountsChanged" || e.data.message.action === "setAccount")) {
        if (e.data.message.data.address) {
          this.conectar();
          if (this.state.estatus['loggedIn'] && this.state.contractready) {
            this.estado();

          }

        }
      }
    })
    setTimeout(async () => {
      this.conectar();
      if (this.state.estatus['loggedIn'] && this.state.contractready) {
        this.estado();

      }
    }, 3 * 1000);

    setInterval(async () => {

      this.conectar();
      if (this.state.estatus['loggedIn'] && this.state.contractready) {
        this.estado();

      }


    }, 7 * 1000);

    this.conectar().then(() => {
      //this.test()

    })



  }

  async conectar() {

    let { estatus, wallet, contrato } = this.state;

    wallet = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"

    if (typeof window.tronWeb !== 'undefined') {

      if (!adapter.address) {

        await adapter.connect()
          .then(() => {
            console.log("TronLink Conectado");
            estatus['web3'] = adapter;
            estatus["loggedIn"] = true


          })
          .catch(() => {
            estatus["loggedIn"] = false

          })
      } else {
        estatus["loggedIn"] = true

      }


      wallet = adapter.address



      //https://iujetrtxbxoskh9l1cidv7clngnjnm.mainnet.tron.tronql.com/

      const tronWeb_c = new TronWeb({
        fullHost: 'https://api.trongrid.io',
        headers: { "TRON-PRO-API-KEY": KEYS[1] }
      })

      contrato['web3'] = tronWeb_c;
      contrato['web3'].setAddress(wallet)

      this.setState({
        estatus: estatus,
        wallet: wallet

      });

      if (!arrancado && !this.state.contractready && this.state.estatus['loggedIn']) {
        arrancado = true
        if (cons.SC !== "") {
          contrato.ciro_trx = await contrato['web3'].contract().at(cons.SC);

          contrato.tokenList = []

          var tokenList = await contrato.ciro_trx.tokenList().call();
          let puntos = 0
          let text = ""

          for (let index = 0; index < tokenList[0].length; index++) {

            contrato.tokenList[index] = await contrato['web3'].contract(abi_TOKENTRC20, tokenList[0][index]);
            //await delay(1)

            text = text + "."
            puntos++
            if (puntos > 3) {
              text = ""
              puntos = 0
            };

            this.setState({
              elementSelect: <option value={0}>Preparing aplication{text}</option>,

            })

            /*
              if (contrato.tokenList[index].implementation && false) {//
                var address_imp = await contrato.tokenList[index].implementation().call()

                if (typeof address_imp !== "string") { address_imp = address_imp[0] }
                if (address_imp !== "410000000000000000000000000000000000000000") {
                  let contract_imp = await contrato['web3'].contract().at(address_imp)
                  contrato.tokenList[index] = await contrato['web3'].contract(contract_imp.abi, tokenList[0][index]);
                }

              }
            */

          }

          this.setState({
            contrato: contrato,
            contractready: true
          });

          this.estado()

        }
      }

    } else {


      this.setState({
        wallet: wallet

      });


    }
  }

  async estado() {

    let cuenta = await this.state.contrato['web3'].trx.getAccountResources("TRrhyn55AtGEjgaLpj9sTbRGhqzVJ8ueNs");

    let idMoneda = this.state.idMoneda

    let energyContrato = 0

    if (cuenta.EnergyLimit) {
      energyContrato = cuenta.EnergyLimit
    }

    if (cuenta.EnergyUsed) {
      energyContrato -= cuenta.EnergyUsed
    }

    cuenta = await this.state.contrato['web3'].trx.getAccountResources(this.state.wallet);

    let energyWallet = 0

    if (cuenta.EnergyLimit) {
      energyWallet = cuenta.EnergyLimit
    }

    if (cuenta.EnergyUsed) {
      energyWallet -= cuenta.EnergyUsed
    }

    this.setState({
      energyContrato: energyContrato,
      energyWallet: energyWallet
    })

    var balance = await this.state.contrato['web3'].trx.getBalance(this.state.wallet);
    balance = new BigNumber(balance).shiftedBy(-6).toNumber();

    if (balance <= 0) {
      this.setState({
        modalTitle: "Alert!",
        modalBody: "Need some TRX to pay bandwidth"
      })

      window.$("#exampleModal").modal("show");
    }

    if (!cargandoTokens && this.state.contractready && this.state.contrato.tokenList.length > 0) {
      cargandoTokens = true

      var tokenList = await this.state.contrato.ciro_trx.tokenList().call();


      let elementSelect = [];

      let tokenContratos = [];

      let fees = [];

      for (let index = 0; index < tokenList[0].length; index++) {

        this.setState({
          elementSelect: <option value={0}>Tokens Left( {tokenList[0].length - index} ) for Loading.</option>,

        })

        let decimals = await this.state.contrato.tokenList[index].decimals().call()
        //await delay(1)

        if (decimals._hex) {
          decimals = parseInt(decimals._hex)
        }

        let moneda = {}
        moneda.symbol = await this.state.contrato.tokenList[index].symbol().call()
        //await delay(1)
        moneda.name = await this.state.contrato.tokenList[index].name().call()
        //await delay(1)
        moneda.decimals = decimals


        let fee = 0;
        if (tokenList[2][index]) {
          fee = tokenList[1][index];
          if (fee._hex) {
            fee = fee._hex
          }

          fee = new BigNumber(fee).shiftedBy(-moneda.decimals)
          fees[index] = fee.toNumber()

          fee = fee.toString(10) + " " + moneda.symbol
        } else {
          fee = (tokenList[1][index] / tokenList[3][index]) * 100
          fees[index] = fee

          fee = fee + " %"
        }

        elementSelect[index] = <option key={"objets" + index} value={index}>{moneda.symbol} ({moneda.name}) - Fee {fee} </option>

        tokenContratos[index] = moneda

      }

      this.setState({
        elementSelect: elementSelect,
        tokenSelected: tokenContratos[idMoneda].symbol,
        tokenContratos: tokenContratos,
        fees: fees,
      })
    }

    if (this.state.tokenContratos.length > 0) {

      var decimals2 = this.state.tokenContratos[idMoneda].decimals
      var disponible = await this.state.contrato.tokenList[idMoneda].balanceOf(this.state.wallet).call()
      if (disponible._hex) {
        disponible = disponible._hex
      }
      disponible = new BigNumber(disponible).shiftedBy(-decimals2)

      this.setState({
        disponible: disponible.toNumber()

      });

    }
  }

  async opciones() {

    var tokenContratos = this.state.tokenContratos;
    var fees = this.state.fees;
    var idMoneda = document.getElementById("token").value;

    this.setState({
      idMoneda: idMoneda
    })

    if (tokenContratos.length <= 0) return;


    var disponible = await this.state.contrato.tokenList[idMoneda].balanceOf(this.state.wallet).call()
    if (disponible._hex) {
      disponible = disponible._hex
    }


    disponible = new BigNumber(disponible).shiftedBy(-tokenContratos[idMoneda].decimals)

    var amount = document.getElementById("amount").value;
    amount = amount.replace(",", ".")
    amount = new BigNumber(document.getElementById("amount").value);

    if (amount.toNumber() > disponible.toNumber()) {
      amount = disponible
      document.getElementById("amount").value = disponible.toString(10)
    }

    if (amount > fees[idMoneda]) {
      amount = amount.minus(fees[idMoneda])

    } else {
      amount = 0
    }

    this.setState({
      tokenSelected: await tokenContratos[idMoneda].symbol,
      disponible: disponible.toString(10),
      recivedAmount: amount.toString(10),
    });

  }

  async recursos() {
    var url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + "available"
    var consulta = await fetch(url).then((r) => r.json())

    var band = consulta["BANDWIDTH_-_Rental_duration_less_or_equal_to_3_days"]
    var energi = consulta["ENERGY_-_Rental_duration_less_or_equal_to_3_days"]

    this.setState({
      available_bandwidth: band,
      available_energy: energi,
      total_bandwidth_pool: consulta.total_bandwidth_pool,
      total_energy_pool: consulta.total_energy_pool
    });
  }

  async calcularRecurso(amount) {

    await this.recursos();
    var url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + "prices"

    var body = { "resource": "ENERGY", "amount": amount, "duration": "5min" }

    this.setState({
      precio: "Calculating..."
    })

    var consulta2 = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then((r) => r.json())

    console.log(consulta2)

    var precio = consulta2.price * 1
    precio = parseInt(precio * 10 ** 6) / 10 ** 6

    return precio

  }

  async test() {
    const unSignedTransaction = await tronWeb.transactionBuilder.sendTrx(process.env.REACT_APP_WALLET_API, (1 * 10 ** 6).toString(10), adapter.address);
    // using adapter to sign the transaction
    const signedTransaction = await adapter.signTransaction(unSignedTransaction)
      .catch((e) => {
        this.setState({
          modalTitle: "Transaction failed",
          modalBody: <>{e.toString()}
            <br /><br />
            <button type="button" className="btn btn-danger" onClick={() => { window.$("#exampleModal").modal("hide") }}>Close</button>
          </>
        })

        window.$("#exampleModal").modal("show");
        return false;
      })
    // broadcast the transaction

    if (!signedTransaction) { return; }
    let hash = await tronWeb.trx.sendRawTransaction(signedTransaction);

    console.log(hash)
  }

  async rentEnergy(cantidad) {

    var precio = await this.calcularRecurso(cantidad, "5min")
    var recurso = "energy"
    var time = "5min"
    var wallet_orden = this.state.wallet

    const imgLoading = <img src="assets/images/cargando.gif" height="20px" alt="loading..." />

    if (await this.calcularRecurso(cantidad, "5min") > await this.state.contrato['web3'].trx.getUnconfirmedBalance(wallet_orden) / 10 ** 6) {

      this.setState({
        modalTitle: <>Insufficient balance to rent resources for the first time</>,
        modalBody: <>You need at least {precio + 1} trx to complete the energy rental this first time with this token and then you can use it only having enough bandwidth for the transaction</>
      })

      window.$("#exampleModal").modal("show");
      return;
    }



    if (this.state.energyWallet < cantidad) {

      this.setState({
        modalTitle: <>Confirm transaction {imgLoading}</>,
        modalBody: <>Please confirm the transaction from your wallet </>
      })

      window.$("#exampleModal").modal("show");

      const unSignedTransaction = await tronWeb.transactionBuilder.sendTrx(process.env.REACT_APP_WALLET_API, (precio * 10 ** 6).toString(10), adapter.address);
      // using adapter to sign the transaction
      const signedTransaction = await adapter.signTransaction(unSignedTransaction)
        .catch((e) => {
          this.setState({
            modalTitle: "Transaction failed",
            modalBody: <>{e.toString()}
              <br /><br />
              <button type="button" className="btn btn-danger" onClick={() => { window.$("#exampleModal").modal("hide") }}>Close</button>
            </>
          })

          window.$("#exampleModal").modal("show");
          return false;
        })
      // broadcast the transaction

      if (!signedTransaction) { return; }

      let hash = await tronWeb.trx.sendRawTransaction(signedTransaction);

      console.log(hash)

      this.setState({
        modalTitle: <>Waiting for the blockchain {imgLoading}</>,
        modalBody: "We are waiting for the blockchain to process and confirm your transfer. This can take from 3 seconds to 1 minute"
      })

      window.$("#exampleModal").modal("show");

      await delay(3);

      let envio = hash.transaction.raw_data.contract[0].parameter.value

      this.setState({
        modalTitle: <>we are verifying {imgLoading}</>,
        modalBody: "We are verifying that the amounts and the address to which the funds were sent are the correct address, please do not close or exit the website as this may affect this process."
      })

      window.$("#exampleModal").modal("show");

      if (hash.result && envio.amount + "" === this.state.contrato['web3'].toSun(precio) && this.state.contrato['web3'].address.fromHex(envio.to_address) === process.env.REACT_APP_WALLET_API) {

        hash = await this.state.contrato['web3'].trx.getTransaction(hash.txid);

        if (hash.ret[0].contractRet === "SUCCESS") {

          this.setState({
            modalTitle: <>we are allocating your {recurso} {imgLoading}</>,
            modalBody: "Please do not close or leave the page as this will cause an error in the " + recurso + " allocation."
          })

          window.$("#exampleModal").modal("show");

          var url = "https://cors.brutusservices.com/" + process.env.REACT_APP_BOT_URL + recurso

          var body = {
            "id_api": process.env.REACT_APP_USER_ID,
            "wallet": wallet_orden,
            "amount": cantidad,
            "time": time,
            "user_id": "fromCiro"
          }

          var consulta2 = await fetch(url, {
            method: "POST",
            headers: {
              'token-api': process.env.REACT_APP_TOKEN,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          }).then((r) => r.json())

          //console.log(consulta2)

          if (consulta2.response === 1) {

            this.setState({
              modalTitle: "Completed successfully",
              modalBody: <><p>Energy rental completed successfully. </p><button type="button" data-bs-dismiss="modal" className="btn btn-success">continue</button></>
            })
            window.$("#exampleModal").modal("show");

            return true;

          } else {

            var msg = consulta2.msg || "";

            this.setState({
              modalTitle: "Contact support",
              modalBody: "Please contact support for: " + hash.txID + " | " + msg
            })
            window.$("#exampleModal").modal("show");

          }

        } else {
          this.setState({
            modalTitle: "Contact support",
            modalBody: "Please contact support for: Error SUC-808831"
          })
          window.$("#exampleModal").modal("show");
        }


      } else {
        this.setState({
          modalTitle: "Contact support",
          modalBody: "Please contact support for: Error NN-0001"
        })
        window.$("#exampleModal").modal("show");
      }

    } else {
      return true
    }

  }

  async compra() {

    const imgLoading = <img src="assets/images/cargando.gif" height="20px" alt="loading..." />

    var idMoneda = document.getElementById("token").value;

    const minCompra = 10 ** 6

    var amount = document.getElementById("amount").value;
    var wallet_destino = document.getElementById("wallet").value;

    const contract_token = this.state.contrato.tokenList[idMoneda];

    var balance = parseInt((await contract_token.balanceOf(this.state.wallet).call())._hex)
    var aproved = await contract_token.allowance(this.state.wallet, this.state.contrato.ciro_trx.address).call()

    amount = amount.replace(",", ".")
    amount = parseFloat(amount);
    var decimal = await contract_token.decimals().call()
    if (decimal._hex) {
      decimal = parseInt(decimal._hex)
    }
    amount = new BigNumber(amount).shiftedBy(decimal)

    if (aproved.remaining) {
      aproved = aproved.remaining
    }

    aproved = parseInt(aproved._hex)

    if (this.state.energyContrato >= 100000) {

      if (amount.toNumber() > aproved) {

        this.setState({
          modalTitle: "Token Alert",
          modalBody: <p>It is the first time that you are going to make a shipment with this Token, this first transaction will be more expensive because it is an external contract, we recommend that you rent energy to continue the process successfully
            <br></br><button className="btn btn-success" onClick={async () => {
              if (await this.rentEnergy(60000)) {

                let inputs = [
                  { type: 'address', value: window.tronWeb.address.toHex(this.state.contrato.ciro_trx.address) },
                  { type: 'uint256', value: "115792089237316195423570985008687907853269984665640564039457584007913129639935" }
                ]

                let funcion = "approve(address,uint256)"
                const options = {}//{ callValue: amount }
                let trigger = await tronWeb.transactionBuilder.triggerSmartContract(window.tronWeb.address.toHex(contract_token.address), funcion, options, inputs, window.tronWeb.address.toHex(this.state.wallet))
                let transaction = await tronWeb.transactionBuilder.extendExpiration(trigger.transaction, 180);
                transaction = await adapter.signTransaction(transaction)
                  .catch((e) => {

                    this.setState({
                      modalTitle: "error",
                      modalBody: e.toString()
                    })

                    window.$("#exampleModal").modal("show");
                  })
                transaction = await tronWeb.trx.sendRawTransaction(transaction)
                  .then((r) => {
                    this.setState({
                      ModalTitulo: "Corerct",
                      ModalBody: <>aproving is done</>
                    })

                    window.$("exampleModal").modal("show");
                    return r;
                  })

                //await contract_token.approve(this.state.contrato.ciro_trx.address, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send()

                if (transaction.txid) {
                  this.compra();

                }

              }
            }}>Continue renting energy</button><br></br>
          </p>
        })

        window.$("#exampleModal").modal("show");

        return;

      }

      if (balance >= amount.toNumber()) {
        if (amount.toNumber() >= minCompra) {

          if (!this.state.contrato['web3'].isAddress(wallet_destino)) {
            this.setState({
              modalTitle: "Input error",
              modalBody: "Invalid Wallet"
            })

            window.$("#exampleModal").modal("show");
            return;
          }

          this.setState({
            modalTitle: <>Sing transaction {imgLoading}</>,
            modalBody: <>Please Sing the transaction from your wallet </>
          })

          window.$("#exampleModal").modal("show");

          let inputs = [
            { type: 'address', value: window.tronWeb.address.toHex(wallet_destino) },
            { type: 'uint256', value: amount.toString(10) },
            { type: 'uint256', value: idMoneda }

          ]

          let funcion = "transfer(address,uint256,uint256)"
          const options = {}//{ callValue: amount }
          let trigger = await tronWeb.transactionBuilder.triggerSmartContract(window.tronWeb.address.toHex(this.state.contrato.ciro_trx.address), funcion, options, inputs, window.tronWeb.address.toHex(this.state.wallet))
          let transaction = await tronWeb.transactionBuilder.extendExpiration(trigger.transaction, 180);
          transaction = await adapter.signTransaction(transaction)
            .catch((e) => {

              this.setState({
                modalTitle: "error",
                modalBody: e.toString()
              })

              window.$("#exampleModal").modal("show");
            })
          transaction = await tronWeb.trx.sendRawTransaction(transaction)
            .then((r) => {
              this.setState({
                ModalTitulo: "Transaction",
                ModalBody: <>sent to the blockchain</>
              })

              window.$("#exampleModal").modal("show");
              return r;
            })

          //await this.state.contrato.ciro_trx.transfer(wallet, amount.toString(10), idMoneda).send();

          this.setState({
            modalTitle: <>Waiting for the blockchain {imgLoading}</>,
            modalBody: "We are waiting for the blockchain to process and confirm your transfer. This can take from 5 seconds to 1 minute"
          })

          window.$("#exampleModal").modal("show");
          await delay(5)

          let result = await this.state.contrato['web3'].trx.getTransaction(transaction.txid);

          if (result.ret[0].contractRet === "SUCCESS") {
            this.setState({
              modalTitle: "Transacction successful",
              modalBody: <>Your send is done!<br />
                <a href={"https://tronscan.io/#/transaction/" + transaction.txid} target="_blank" rel="noreferrer noopener">See on TronScan</a></>
            })

          } else {
            this.setState({
              modalTitle: "Transacction failed",
              modalBody: "Apparently there was an error in the transaction, please contact support. hash: " + result.ret[0].txId
            })

          }

          window.$("#exampleModal").modal("show");

          document.getElementById("amount").value = "";


        } else {

          this.setState({
            modalTitle: "input error",
            modalBody: "Please enter an amount greater amount"
          })

          window.$("#exampleModal").modal("show");

        }

      } else {

        this.setState({
          modalTitle: "input error",
          modalBody: "You don't have enough tokens to make the payment"
        })

        window.$("#exampleModal").modal("show");

        document.getElementById("amount").value = "";

      }

    } else {

      this.setState({
        modalTitle: "Sold Out",
        modalBody: "All resources have been sold to carry out transactions paid for with stable tokens"
      })

      window.$("#exampleModal").modal("show");

    }

    this.estado();

  };


  render() {

    let status = "🟢"


    if (this.state.energyContrato <= 75000) {
      status = "🔴"
    }



    return (

      <>
        <div className="contact-form-area style-two pt-100 pb-100">

          <div className="container">
            <div className="row">
              <div className="dreamit-section-title text-center upper1 pb-70">
                <h4>CiroTrx</h4>
                <h1 className="section-title">Stablecoin on TRON made simple</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-none d-sm-block">
                <div className="contact-form-thumb wow fadeInRight" data-wow-delay=".4s">
                  <img src="assets/images/resource/CIROTRX.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row">
                  <div className="contact-form-box wow fadeInLeft" data-wow-delay=".4s">
                    <div className="contact-form-title">
                      <div className="container">
                        <div className="row">
                          <div className="col-6">
                            <h3>CiroTrx  </h3>

                          </div>
                          <div className="col-6">
                            <p style={{ fontSize: "0.9rem", color: "#808080" }}><span className="text-right" >Status: {status} </span></p>

                          </div>

                        </div>
                      </div>

                    </div>
                    <form id="dreamit-form">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white">Recipient wallet</p>
                          <div className="from-box">
                            <input type="text" id="wallet" placeholder="Paste or enter the account address" />
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white">Choose a stablecoin</p>

                          <div className="from-box">
                            <div className="row">
                              <div className="col-2">
                                <img className="" style={{ margin: "7px 20px", width: "35px", height: "35px" }} src={"assets/images/" + this.state.tokenSelected + "-logo.png"} alt={this.state.tokenSelected + "-logo"} />
                              </div>
                              <div className="col-10">
                                <select name="select" id="token" onChange={() => { this.opciones() }} style={{ padding: "6px 20px", borderRadius: "30px", width: "100%", height: "54px", marginBottom: "20px", backgroundColor: "transparent", color: "#8e8e8e", border: "1px solid #353D51" }}>
                                  {this.state.elementSelect}
                                </select>
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="col-lg-12 col-sm-12">
                          <p className="text-white" style={{ fontSize: "0.9rem", color: "#808080" }}>Available: {this.state.disponible} {this.state.tokenSelected}</p>

                          <div className="from-box">
                            <input type="number" id="amount" onChange={() => { this.opciones() }} placeholder="0" min={0} />
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 btn-group" role="group" >
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = ((this.state.disponible) * 0.25).toPrecision(6); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>25%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = ((this.state.disponible) * 0.50).toPrecision(6); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>50%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = ((this.state.disponible) * 0.75).toPrecision(6); this.opciones() }} style={{ marginRight: "7px", borderRadius: "10px", backgroundColor: "#1DD1A1" }}>75%</button>
                          <button type="button" className="btn btn-success" onClick={() => { document.getElementById("amount").value = ((this.state.disponible) * 1).toPrecision(6); this.opciones() }} style={{ borderRadius: "10px", backgroundColor: "#1DD1A1" }}>100%</button>

                        </div>
                      </div>
                      <div className="from-box mt-3">
                        <button type="button" style={{ width: "100%" }} onClick={async () => { await this.opciones(); this.compra() }}>Send {this.state.recivedAmount} {this.state.tokenSelected}</button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                See last alert message
              </button>
            </div>
          </div>

        </div>



        <div className="modal" id="exampleModal" style={{ zIndex: 999 }} aria-labelledby="exampleModalLabel" aria-hidden="true" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark" id="exampleModalLabel">{this.state.modalTitle}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {this.state.modalBody}
              </div>
              <div className="modal-footer">

              </div>
            </div>
          </div>
        </div>

      </>

    );
    //<button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
  }
}
